import { constructLink } from "@/utils/linkConstructor";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

import { airportStore } from "@/stores";
import { convertEntityKeyToName } from "@/utils/linkConstructor";

const BestCarRentalAirport = ({ data, type }: { data: any; type?: string }) => {
  const [listTrim, setListTrim] = useState(false);
  const [listData, setListData] = useState(data);
  useEffect(() => {
    if (window.innerWidth < 1024) {
      setListData(data.slice(0, 8));
      setListTrim(true);
    }
  }, []);
  const toggleList = () => {
    listTrim ? setListData(data) : setListData(data.slice(0, 8));
    setListTrim(!listTrim);
  };
  return (
    <div className="lg:border lg:border-[#D8DFEE] rounded-[20px] py-[34px] px-[27px] w-full mx-auto">
      {" "}
      {/* Change to w-fit because this looks bad */}
      <div className="flex items-center justify-start gap-3 mb-4">
        <div className="p-[15px] bg-[#0057FF] rounded-[20px] w-[60px] h-[60px] flex items-center justify-center aspect-square">
          <img
            loading="lazy"
            src="/best-car-rental/globe.svg"
            alt="Icon of a globe"
            className="min-h-[60px] min-w-[60px] rounded-xl"
          />
        </div>
        <h2 className="font-extrabold lg:font-bold text-[18px] lg:text-[22px] text-my-dark-blue">
          {" "}
          {/* Add max-w-[345px] when enabling other boxes */}
          {type === "mid"
            ? "Best car Rentals Within Airports in the United States"
            : "Best car Rental in Airports Worldwide"}
        </h2>
      </div>
      <hr />
      {/* FIXME: Set max h to 575px when enabling other segments and also overflow scroll */}
      <div className={`mt-[18px] grid lg:grid-cols-3`}>
        {listData.length > 0 &&
          listData.map((item: any) => {
            let url;
            if (item.entityKey || item.location) {
              let locationName: string = constructLink(item);
              url = `https://rent80.com/${locationName}`;
            }

            if (url) {
              return (
                <Link
                  href={url}
                  key={url}
                  onClick={() => {
                    const countryCode = item.cc === "US" ? "USA" : item.cc;
                    airportStore.setAirport(
                      `${convertEntityKeyToName(item.entityKey)} [${
                        item.id
                      }], ${countryCode}`,
                      item.id,
                      item.cityonly
                    );
                  }}
                >
                  <div className="flex items-center justify-start gap-[10px] py-[9px]">
                    <Image
                      src="/best-car-rental/map-pin.svg"
                      height={24}
                      width={24}
                      alt="Map pin icon"
                    />
                    <p className="font-normal text-base text-[#004DE3]">
                      {item.cityonly === item.region
                        ? item.cityonly
                        : item.cityonly + ", " + item.region}
                      ,{" "}
                      {
                        // item.country ==="United States"? "USA": item.country
                        //TODO: Enable the conditional when in production and remove the static "USA" string
                        "USA"
                      }{" "}
                      {`(${item.id})`}
                    </p>
                  </div>
                </Link>
              );
            }
          })}
        {listTrim && window.innerWidth < 1024 && (
          <button
            className="border-t-[0.5px] pt-[18px] text-[#004DE3] lg:hidden block"
            onClick={() => toggleList()}
          >
            {`${listTrim ? "View" : "Hide"} more`}
          </button>
        )}
      </div>
      <div className="grid lg:hidden">
        <hr className="mt-4" />
        {/* <div className="w-full p-4">
          <p className="text-center text-[#334B6D]">See more providers</p>
        </div> */}
      </div>
    </div>
  );
};
export default BestCarRentalAirport;
