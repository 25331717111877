export function convertEntityKeyToName(entityKey: string) {
  if (!entityKey) {
    // Handle the error or return a default value here
    return "";
  }
  const parts = entityKey.replace("place:", "").split("_");

  const name = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");

  return name;
}

export function constructLink(item: any) {
  if (!item) {
    // Handle the error or return a default value here
    return "";
  }
  // Extract and format the properties
  let airportName = convertEntityKeyToName(item?.entityKey);
  let apCode = item?.ap;
  let countryCode = item?.cc;

  let formatCountryCode = countryCode === "US" ? "USA" : countryCode;

  // Create the string
  let airportString = `car-hire-${airportName}, ${apCode}, ${formatCountryCode}`;

  // Format the string
  airportString = airportString.replace(/\[.*?\]/g, ""); // Remove everything within square brackets
  airportString = airportString.replace(/,/g, ""); // Remove commas
  airportString = airportString.replace(/\./g, ""); // Remove periods
  airportString = airportString.replace(/ /g, "-"); // Replace spaces with '-'
  airportString = airportString.replace(/--+/g, "-"); // Replace multiple '-' with a single '-'
  airportString = airportString.toLowerCase(); // Convert to lowercase
  return `${airportString}-${item.ctid}`;
}
